<template>
    <div class="popup-item lock">
        <div class="lock__icon">
            <i class="lock__icon-item"></i>
        </div>
        <h4 class="lock__title">Время этого бонуса ещё не настало</h4>
        <div class="lock__button">
            <Button :click="closePopup" :styles="{'font-weight': 600}">Принять</Button>
        </div>
    </div>
</template>

<script>
import {popupMixin} from "../../mixins/popup"
import Button from "./Button"

export default {
    mixins: [popupMixin],
    components: {
        Button
    }
}
</script>

<style lang="scss">
.lock {
    &__icon {
        display: flex;
        align-items: center;
        justify-content: center;

        &-item {
            display: block;
            width: 60px;
            height: 80px;
            background-size: 58px 80px;
            background-position: center;
            background-repeat: no-repeat;
            background-image: url('../../assets/deer.png');
        }
    }

    &__title {
        font-size: 30px;
        line-height: 32px;
        text-align: center;
        margin-top: 30px;
        margin-bottom: 35px;
    }
}
</style>